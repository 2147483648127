<script lang="ts">
  import { createForm } from 'svelte-forms-lib';
  import * as yup from 'yup';
  import { AuthenticationService, getUser } from '../services/auth/authenticationService';
  import { replace } from 'svelte-spa-router';
  import {accessTokenStore,userAccountStore,} from "@common/stores/auth-store";

  let loginErrorMessage: string = '';
  let isSigningIn: boolean = false;
  let forgotPassword: boolean = false;

  const {
    form,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleReset,
    isValid
  } = createForm({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required('A valid email is required.'),
      password: yup.string().required('A valid password is required'),
    }),
    onSubmit: async (formValues) => {
      if (forgotPassword) {
        
        try {
          const response = await AuthenticationService.forgotUserPassord(
            formValues.email,
          );
          
          if (response.status === 204) {
            alert('Nytt passord har blitt sendt til deg på mail.');
          }

        } catch (e) {

          if (e?.message === 'Network Error') {
            loginErrorMessage =
              'Authentication service currently unavailable. Please try again later.';
          } else if (
            e?.response?.status === 400 ||
            e?.response?.status === 404
          ) {
            loginErrorMessage = <string>(
              e?.response?.data?.errorDetails?.join(' | ')
            );
          } else {
            loginErrorMessage = JSON.stringify(e);
          }
        }
        
      } else {
        try {
          await loginUser(formValues);
        } catch (e) {
          console.error(e);
        }
      }
    },
  });

  $: if ($form) {
    loginErrorMessage = '';
  }

  const loginUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    loginErrorMessage = "";
    try {
      isSigningIn = true;
      const response = await AuthenticationService.signIn({
        Email: email,
        Password: password,
      });


      if (response.status === 200) {
        accessTokenStore.set(response.data.accessToken);

        // the /Account/Login controller does not provide user data, so we make an additional HTTP request to retrieve it
        const user = await getUser();
        void replace('/profile');
        isSigningIn = false;
        if (user) {
        
          return true;
        }
      } else {
        isSigningIn = false;
        accessTokenStore.set("");
        userAccountStore.set({});
        return false;
      }
    } catch (e) {
      isSigningIn = false;
      if (e?.message === "Network Error") {
        loginErrorMessage =
          "Authentication service currently unavailable. Please try again later.";
      } else if (e?.response?.status === 400 || e?.response?.status === 404) {
        loginErrorMessage = e?.response?.data?.errorDetails?.join(" | ");
      } else if (e?.message === "Network Error") {
        loginErrorMessage =
          "Cannot reach authentication service. Please try again later.";
      }

      handleReset();
      //throw new Error(JSON.stringify(e.response, null, 2));
    }
  };
</script>

<section class="flex flex-col py-12 sm:px-6 lg:px-8">
  <div class="mt-8 sm:mx-auto w-full xl:w-1/3">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form class="space-y-6" on:submit|preventDefault={handleSubmit}>
        <div>
          <label class="block text-sm font-medium text-gray-700" for="email">
            Epost-adresse
            {#if $errors.email} <small>{$errors.email}</small> {/if}
          </label>
          <div class="mt-1">
            <input
              autocomplete="email"
              bind:value={$form.email}
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="email"
              name="email"
              on:change={handleChange}
              type="email"
            />
          </div>
        </div>

        {#if !forgotPassword}
          <div>
            <label
              class="block text-sm font-medium text-gray-700"
              for="password"
            >
              Passord
            </label>
            <div class="mt-1">
              <input
                autocomplete="current-password"
                bind:value={$form.password}
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="password"
                name="password"
                on:change={handleChange}
                type="password"
              />
            </div>
          </div>
        {/if}

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            {#if !forgotPassword}
              <input
                class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                id="remember_me"
                name="remember_me"
                type="checkbox"
              />
              <label class="ml-2 block text-sm text-gray-900" for="remember_me">
                Husk innloggingsinformasjon
              </label>
            {/if}
          </div>

          <div class="flex items-center">
            <input
              class="h-4 w-4 focus:ring-indigo-500 border-gray-300 rounded"
              id="forgot_password"
              name="forgot_password"
              type="checkbox"
              bind:checked={forgotPassword}
            />
            <label
              class="ml-2 block text-sm text-primary-darker"
              for="forgot_password"
            >
              Glemt passord
            </label>
          </div>
        </div>

        {#if isSigningIn}
          <div
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-darker"
          >
            Logger inn...
          </div>
        {:else if forgotPassword}
          <button
            disabled={$isSubmitting || isSigningIn}
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-darker hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            type="submit"
          >
            Nullstill passord
          </button>
        {:else}
          <button
            disabled={$isSubmitting || isSigningIn}
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-darker hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            type="submit"
          >
            Logg inn på Krono
          </button>
        {/if}

        {#if loginErrorMessage.length > 0}<p class="text-red-700">
            <span class="font-medium">Error:</span>
            {loginErrorMessage}
          </p>
        {/if}
      </form>
    </div>
  </div>
</section>

<style>
  small {
    color: red;
  }
</style>
