// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const {VITE_API_BASEURL} = import.meta.env;

const accountPath: string = 'Account';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ApiBaseURL: string = <string>VITE_API_BASEURL;

// All available actions based on SmartGis.API AccountController
export const UserAuthActions = {
    Login: 'Login',
    Register: 'Register',
    ConfirmEmail: 'ConfirmEmail',
    ForgotPassword: 'ForgotPassword',
    ResetPassword: 'ResetPassword',
    Me: 'Me', // Retrieve profile data
};

// Paths based on SmartGis.API AccountController
export const AuthenticationPaths = {
    Login: `${ApiBaseURL}/${accountPath}/${UserAuthActions.Login}`,
    Register: `${ApiBaseURL}/${accountPath}/${UserAuthActions.Register}`,
    ConfirmEmail: `${ApiBaseURL}/${accountPath}/${UserAuthActions.ConfirmEmail}`,
    ForgotPassword: `${ApiBaseURL}/${accountPath}/${UserAuthActions.ForgotPassword}`,
    ResetPassword: `${ApiBaseURL}/${accountPath}/${UserAuthActions.ResetPassword}`,
    Me: `${ApiBaseURL}/${accountPath}/${UserAuthActions.Me}`
};