import axios, { AxiosError } from 'axios';
import { get } from 'svelte/store';
import { accessTokenStore } from '@common/stores/auth-store';
import { VITE_API_BASEURL } from '@services/auth/apiAuthorizationConstants';
import { AuthenticationService } from '@services/auth/authenticationService';

export const ApiBaseURL: string = <string>VITE_API_BASEURL;

axios.defaults.headers.post['Content-Type'] = 'application/json';

// Not authenticated
const axiosUnauthenticatedInstance = axios.create({
  baseURL: ApiBaseURL
});

// Authenticated
const axiosAuthenticatedInstance = axios.create({
  baseURL: ApiBaseURL,
});
//axiosAuthenticatedInstance.defaults.headers.common['Authorization'] = `Bearer ${get(accessTokenStore)}`;

axiosAuthenticatedInstance.interceptors.request.use(
  config => {
      if (get(accessTokenStore).length > 0) {
          config.headers['Authorization'] = 'Bearer ' + get(accessTokenStore);
      }
      // config.headers['Content-Type'] = 'application/json';
      return config;
  },
  error => {
      void Promise.reject(error);
  })

axiosAuthenticatedInstance.interceptors.response.use(response => {
  return response;
}, async (error: AxiosError) => {
  console.error('error', error)
  // eslint-disable-next-line no-debugger
  if (error?.message === 'Network Error') {
    void AuthenticationService.signOut();
  } else {
    return Promise.reject(error);
  }
});

export {
  axiosAuthenticatedInstance,
  axiosUnauthenticatedInstance
};