import type { AxiosResponse } from 'axios';
import { get } from 'svelte/store';
import { AuthenticationPaths } from './apiAuthorizationConstants';
import type { IUserModel } from '@common/models/IUserModel';
import { accessTokenStore, isAuthenticatedStore, userAccountStore } from '@common/stores/auth-store';
import { axiosUnauthenticatedInstance, axiosAuthenticatedInstance } from './axiosInstances';
import { replace } from 'svelte-spa-router';

export interface IMeResponseModel {
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  id: number;
}

export interface IAccessTokenModel {
  accessToken: string;
}

export interface IConfirmMailModel {
  userId: string;
  code: string;
}

export const AuthenticationService = {
  async registerUser({ email, password, firstName, lastName }: IUserModel): Promise<AxiosResponse<unknown>> {
    return axiosUnauthenticatedInstance.post<unknown, AxiosResponse<unknown>>(AuthenticationPaths.Register, {
      email,
      password,
      firstName,
      lastName
    });
  },
  async signIn({ Email, Password }: { Email: string, Password: string }): Promise<AxiosResponse<IAccessTokenModel>> {

    return await axiosUnauthenticatedInstance.post<IAccessTokenModel, AxiosResponse<IAccessTokenModel>>(AuthenticationPaths.Login, {
        Email,
        Password
      });
  },
  async confirmUser(userId: string, code: string): Promise<AxiosResponse<IConfirmMailModel>> {
    const response = await axiosUnauthenticatedInstance.post<IConfirmMailModel, AxiosResponse<IConfirmMailModel>>(AuthenticationPaths.ConfirmEmail, {
      userId,
      code
    });

    if (response.status === 200) {
      console.log('Bruker er verifisert.. Gå til forside')
    } else {
      console.warn('En feil oppsto ved verifisering')
    }

    return response;
  },

  signOut(): void {
    userAccountStore.set({});
    accessTokenStore.set('');
    void replace('/');
  },

  async forgotUserPassord(email: string): Promise<AxiosResponse> {
    return await axiosUnauthenticatedInstance.post<AxiosResponse>(AuthenticationPaths.ForgotPassword, {
      email
    });
  }
};

export const getUser = async (): Promise<Partial<IMeResponseModel> | null> => {
  const user = get<Partial<IMeResponseModel>>(userAccountStore);
  const isUserDataAvailable = Object.keys(user).length > 0;
  const userToken = get<string>(accessTokenStore);

  if (isUserDataAvailable) {
    return user;
  } else if (!isUserDataAvailable && userToken.length > 0) {
    // No user is is present, but a user token exists. Grab user data from the /Account/Me SmartGis.API endpoint.
    try {
      const response = await axiosAuthenticatedInstance.get<IMeResponseModel, AxiosResponse<IMeResponseModel>>(AuthenticationPaths.Me);
      userAccountStore.set(response?.data);
      return response?.data;

    } catch (e) {
      userAccountStore.set({});
      console.warn(e);
      throw new Error(e);
    }
  } else {
    userAccountStore.set({});
    return null;
  }
};