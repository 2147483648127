import Home from './pages/Home.svelte';
import Login from './pages/Login.svelte';
import NotFound from './pages/NotFound.svelte';
import Help from './pages/Help.svelte';
import { isAuthenticatedStore } from '@common/stores/auth-store';
import { wrap } from 'svelte-spa-router/wrap';
import { get } from 'svelte/store';

const authGuard = () => get(isAuthenticatedStore);

export const routes = {
  '/': Home,
  '/login': Login,

  '/workinghours': wrap({
    asyncComponent: () => import('./pages/WorkingHours.svelte'),
    conditions: [authGuard],
  }),
  '/apikeys': wrap({
    asyncComponent: () => import('./pages/APIKeys.svelte'),
    conditions: [authGuard],
  }),
  '/help': Help,
  '/profile': wrap({
    asyncComponent: () => import('./pages/Profile.svelte'),
    conditions: [authGuard],
  }),
  // Catch-all
  // This is optional, but if present it must be the last
  '*': NotFound,
};
