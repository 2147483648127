<script lang="ts">
  import { link } from 'svelte-spa-router';
  import active from 'svelte-spa-router/active';
  import { isAuthenticatedStore } from '@common/stores/auth-store';
  import { AuthenticationService } from '@services/auth/authenticationService';
  import { slide } from 'svelte/transition';

  let showMobileMenu: boolean = false;
</script>

<nav class="bg-primary-dark shadow">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex justify-between h-16">
      <div class="flex">
        <div class="flex-shrink-0 flex items-center text-white font-bold">
          <!-- Logo is 300x120px -->
          <!-- <img src="/flytr-logo-300x120-white.png" alt="FlytR" class="h-10 mt-2 md:h-10" /> -->
          Krono
        </div>
        <div class="hidden md:ml-6 md:flex md:space-x-8">
          <div class="flex-row items-center px-2 text-white inline-flex gap-1 border-transparent border-b-2 hover:border-white mt-1"
            use:active={{path: '/', inactiveClassName: 'inactive'}}>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <!-- a class="text-gray-100 inline-flex items-center px-1 pt-1 text-sm font-medium" -->
            <a
              class="text-sm font-medium"
              href="/"
              use:link
            >
              Hjem
            </a>
          </div>
          <div class="hidden md:ml-6 md:flex md:space-x-8">
            <div class="flex-row items-center px-2 text-white inline-flex gap-1 border-transparent border-b-2 hover:border-white mt-1"
              use:active={{path: '/workinghours', inactiveClassName: 'inactive'}}>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <!-- a class="text-gray-100 inline-flex items-center px-1 pt-1 text-sm font-medium" -->
              <a
                class="text-sm font-medium"
                href="/WorkingHours"
                use:link
              >
                Timeregistrering
              </a>
            </div>


          <div class="flex-row items-center px-2 text-white inline-flex gap-1 border-transparent border-b-2 hover:border-white mt-1"
            use:active={{path: '/apikeys', inactiveClassName: 'inactive'}}>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
            </svg>
            <!-- a class="text-gray-100 inline-flex items-center px-1 pt-1 text-sm font-medium" -->
            <a
              class="text-sm font-medium"
              href="/apikeys"
              use:link
            >
              API-nøkler
            </a>
          </div>
          <div class="flex-row items-center px-2 text-white inline-flex gap-1 border-transparent border-b-2 hover:border-white mt-1"
            use:active={{path: '/help', inactiveClassName: 'inactive'}}>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <!-- a class="text-gray-100 inline-flex items-center px-1 pt-1 text-sm font-medium" -->
            <a
              class="text-sm font-medium"
              href="/help"
              use:link
            >
              Hjelp
            </a>
          </div>
          {#if $isAuthenticatedStore}
            <div class="flex-row items-center px-2 text-white inline-flex gap-1 border-transparent border-b-2 hover:border-white mt-1"
              use:active={{path: '/profile', inactiveClassName: 'inactive'}}>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              <a
                class="text-sm font-medium"
                href="/profile"
                use:link
              >
                Brukerprofil
              </a>
            </div>
          {/if}
        </div>
      </div>
      <div class="hidden md:ml-6 md:flex md:space-x-8">
        {#if $isAuthenticatedStore}
          <div class="flex-row items-center px-2 text-white inline-flex gap-1 border-transparent border-b-2 hover:border-white mt-1 transition-colors">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <button
              class="text-sm font-medium"
              on:click|preventDefault={() => AuthenticationService.signOut()}
            >
              Logg ut
            </button>
          </div>
        {:else}
          <div class="flex-row items-center px-2 text-white inline-flex gap-1 border-transparent border-b-2 hover:border-white mt-1"
            use:active={{path: '/login'}}>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
            <a
              class="text-sm font-medium"
              href="/login"
              use:link
            >
              Logg inn
            </a>
          </div>
        {/if}
      </div>
      <div class="-ml-2 mr-2 flex items-center md:hidden">
        <!-- Mobile menu button -->
        <button
          on:click|preventDefault={() => showMobileMenu = !showMobileMenu}
          class="inline-flex items-center justify-center p-2 rounded-md text-gray-100 hover:text-gray-200 hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-light"
          type="button"
        >
          <span class="sr-only">Åpne hovedmeny</span>
          <svg
            aria-hidden="true"
            class="block h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6h16M4 12h16M4 18h16"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
          <svg
            aria-hidden="true"
            class="hidden h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6l12 12"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. Previously solved with CSS, changed to Svelte-->
  {#if showMobileMenu}
    <div class="md:hidden" id="mobile-menu" transition:slide>
      <div class="pt-2 pb-3 space-y-1">

        <div 
          class="flex flex-row text-white hover:bg-primary hover:border-primary-lighter hover:text-gray-200 pl-3 pr-4 py-2 text-base sm:pl-5 sm:pr-6 gap-2 items-center"
          use:active={{className: 'mobile-active', path: '/'}}
          on:click|preventDefault={() => showMobileMenu = !showMobileMenu}
        >
          <!-- Icon -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>
          <!-- Text -->
          <a
            href="/"
            use:link>Home</a
          >
        </div>

        <div 
          class="flex flex-row text-white hover:bg-primary hover:border-primary-lighter hover:text-gray-200 pl-3 pr-4 py-2 text-base sm:pl-5 sm:pr-6 gap-2 items-center"
          use:active={{className: 'mobile-active', path: '/apikeys'}}
          on:click|preventDefault={() => showMobileMenu = !showMobileMenu}
        >
          <!-- Icon -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
          </svg>
          <!-- Text -->
          <a
            href="/addanddelete"
            use:link>Add & Delete</a
          >
        </div>
        
        {#if $isAuthenticatedStore}
        <div 
            class="flex flex-row text-white hover:bg-primary hover:border-primary-lighter hover:text-gray-200 pl-3 pr-4 py-2 text-base sm:pl-5 sm:pr-6 gap-2 items-center"
            use:active={{className: 'mobile-active', path: '/profile'}}
            on:click|preventDefault={() => showMobileMenu = !showMobileMenu}
          >
            <!-- Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            <!-- Text -->
            <a href="/profile" use:link>
              User profile
            </a>
          </div>
          <div 
            class="flex flex-row text-white hover:bg-primary hover:border-primary-lighter hover:text-gray-200 pl-3 pr-4 py-2 text-base sm:pl-5 sm:pr-6 gap-2 items-center"
            on:click|preventDefault={() => showMobileMenu = !showMobileMenu}
          >
            <!-- Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <!-- Text -->
            <a href="/logout" on:click|preventDefault={() => AuthenticationService.signOut()}>
              Log out
            </a>
          </div>
        {:else}
          <div 
            class="flex flex-row text-white hover:bg-primary hover:border-primary-lighter hover:text-gray-200 pl-3 pr-4 py-2 text-base sm:pl-5 sm:pr-6 gap-2 items-center"
            use:active={{className: 'mobile-active', path: '/login'}}
            on:click|preventDefault={() => showMobileMenu = !showMobileMenu}
          >
            <!-- Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
            <!-- Text -->
            <a href="/login" use:link>
              Log in
            </a>
          </div>
        {/if}
      </div>
    </div>
  {/if}
</nav>
