import {createWritableStore} from '@common/stores/LocalStorageStore';
import type {ITogglEntryModel} from '@common/models/ITogglEntryModel';
import { Readable } from 'svelte/store';

// Initialise
const togglEntryStore = createWritableStore<ITogglEntryModel[]>('togglEntryStore',  null);

// Configure store to use localStorage
togglEntryStore.useLocalStorage();


export {
    togglEntryStore
};