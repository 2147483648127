import {Writable, writable} from 'svelte/store';

export interface WritableStore<T> {
    subscribe: Writable<T>['subscribe'];
    set: Writable<T>['set'];
    useLocalStorage(): void;
}

// https://github.com/higsch/higsch.me/blob/master/content/post/2019-06-21-svelte-local-storage.md
export const createWritableStore = <T>(key: string, startValue: T) : WritableStore<T> => {
    
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const {subscribe, set} = writable<T>(startValue);

    return {
        subscribe,
        set,
        useLocalStorage: () => {
            const json = localStorage.getItem(key);
            if (json !== undefined && json !== 'undefined' && json !== null) {
                set(JSON.parse(json));
            }

            subscribe(current => {
                localStorage.setItem(key, JSON.stringify(current));
            });
        }
    };
}
