import './global.postcss'
import App from './App.svelte'

import startMockBackend from './mocks/startMockBackend';

if (import.meta.env.VITE_USE_MOCK === 'true') {
  startMockBackend();
}

const app = new App({
  target: document.body
})

export default app
