<script lang="ts">
  import { routes } from './routes';
  import Router, { replace } from 'svelte-spa-router';
  import NavigationBar from './layout/NavigationBar.svelte';
  import { QueryClient, QueryClientProvider } from '@sveltestack/svelte-query';
  import {togglEntryStore} from "@common/stores/togglEntry-store";
  import { onMount } from 'svelte';
 
  const queryClient = new QueryClient();
  togglEntryStore.set([]);

</script>

<QueryClientProvider client={queryClient}>
  <div class="flex flex-col h-screen w-full bg-gray-50">
    <NavigationBar />
    <main class="flex-1 overflow-y-auto">
      <Router on:conditionsFailed={() => replace("/login")} {routes} />
    </main>
  </div>
</QueryClientProvider>


<style>
  :global(a.active, div.active) {
    font-weight: 600;
    border-bottom-width: 3px;
    @apply border-primary-lighter;
    transition: .3s border-color;
  }

  :global(.mobile-active) {
    font-weight: 600;
    @apply bg-primary; 
  }

  :global(.inactive) {
    @apply text-gray-200;
    transition: .3s border-color;
  }

  /* Temporary solution to disable animations for 
  those who has animations disabled in OS settings */
  @media(prefers-reduced-motion: reduce) {
    :global(*) {
      animation-delay: 0ms !important;
      animation-duration: 1ms !important;
    }
  }
</style>
