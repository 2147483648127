import {createWritableStore} from '@common/stores/LocalStorageStore';
import type { IMeResponseModel } from '@services/auth/authenticationService';
import { derived, Readable } from 'svelte/store';

// Initialise authentication stores
const userAccountStore = createWritableStore<Partial<IMeResponseModel>>('userAccountStore', {});
const accessTokenStore = createWritableStore<string>('accessTokenStore', '');
//const isAuthenticatedStore = createWritableStore('isAuthenticated', false);

// Configure both stores to store their values in localStorage
userAccountStore.useLocalStorage();
accessTokenStore.useLocalStorage();

//isAuthenticatedStore.useLocalStorage();

const isAuthenticatedStore: Readable<boolean> = derived(
    userAccountStore,
    $userAccountStore => {
        return Object.keys($userAccountStore).length > 0;
    }
);

export {
    isAuthenticatedStore,
    userAccountStore,
    accessTokenStore
};
